import { computed, defineComponent, PropType, ref } from 'vue';
import styles from './index.css?module';
import {
  Icon,
  IconSize,
  Loader,
  LoaderSize,
  LoaderVariant,
  TextColor,
  Button,
  BtnVariant,
  BtnSize,
} from '@/components/ui';
import { Uploader, UploadError } from '@/service';
import { useS3 } from '@/composables';
import { ToastType, useToast } from '@/store';
import { useI18n } from 'vue-i18n';

export interface UploadFile {
  id?: string;
  path?: string;
  file?: File;
  preview?: string;
  isUploaded: boolean;
  controller?: AbortController;
}

export default defineComponent({
  props: {
    modelValue: {
      type: Object as PropType<UploadFile>,
    },
    onClosed: {
      type: Function as PropType<() => void>,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const toastStore = useToast();
    const media = useS3();

    const model = ref({
      id: props.modelValue?.id,
      path: props.modelValue?.path,
      isUploaded: props.modelValue?.isUploaded ?? false,
    } as UploadFile);

    const filePreview = computed((): string | null => {
      if (model.value?.path && model.value.path.length > 0) {
        return media.original(model.value.path);
      }
      if (model.value.preview && model.value.preview.length > 0) {
        return model.value.preview;
      }
      return null;
    });

    const fileIsUploaded = computed((): boolean => {
      return model.value.isUploaded;
    });

    const onFileChange = (payload: Event) => {
      const input = payload.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
        const fileArray = Array.from(input.files)
          .filter((file) => checkFileType(file))
          .map((file) => makeFile(file));

        if (fileArray.length > 0) {
          model.value = fileArray[0];
          uploadFile(model.value);
        }
      }
    };

    const checkFileType = (file: File) => {
      return true;
    };

    const makeFile = (file: File): UploadFile => {
      return {
        file,
        isUploaded: false,
        controller: new AbortController(),
      };
    };

    const uploadFile = async (file: UploadFile) => {
      if (!file.file) {
        return;
      }

      file.preview = await getImagePreview(file.file);

      const uploader = new Uploader(file.file, file.controller?.signal)
        .setDelayMs(500)
        .setTries(25);

      uploader
        .upload()
        .then((f) => {
          file = Object.assign(file, {
            id: f.id,
            path: f.path,
            isUploaded: true,
          });
          emit('update:modelValue', file);
        })
        .catch((e: UploadError) => {
          if (e.maximumSizeExceeded) {
            toastStore.add(
              ToastType.ERROR,
              t('component.photoPickerCard.toastFileSizeErr')
            );
          } else {
            toastStore.add(
              ToastType.ERROR,
              t('component.photoPickerCard.toastInternalErr')
            );
          }
          onCancel();
        });
    };

    const getImagePreview = (file: File): Promise<string> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result as string);
        };
        reader.onerror = () => {
          reject(null);
        };
        reader.readAsDataURL(file);
      });
    };

    const onCancel = () => {
      if (model.value) {
        model.value.controller?.abort();
        model.value = {} as UploadFile;
        emit('update:modelValue', model.value);
      }
    };

    const previewWrapperClass = computed(() => {
      if (!fileIsUploaded.value) {
        return 'justify-center items-center';
      }
      return 'items-start justify-end';
    });

    return () => (
      <>
        {!filePreview.value && (
          <label class={styles.PhotoCardNew}>
            <input
              class={'hidden'}
              type={'file'}
              multiple={false}
              onChange={onFileChange}
            />
            <Icon
              class={styles.PhotoCardNewIcon}
              name={'plus'}
              size={IconSize.X36}
            />
          </label>
        )}

        {filePreview.value && (
          <div
            class={[styles.PhotoCardPreview, previewWrapperClass.value]}
            style={`background-image: url("${filePreview.value}")`}
          >
            {!fileIsUploaded.value && (
              <div class={styles.PhotoCardPreviewLoading}>
                <Button
                  class={'relative z-10'}
                  variant={BtnVariant.TRANSPARENCY}
                  onClick={onCancel}
                >
                  <Icon
                    class={styles.PhotoCardPreviewLoadingIcon}
                    name={'close'}
                    size={IconSize.X32}
                  />
                </Button>
                <Loader
                  class={'absolute top-0 left-0'}
                  variant={LoaderVariant.SIMPLE}
                  size={LoaderSize.XL}
                />
              </div>
            )}

            {fileIsUploaded.value && (
              <div class={styles.PhotoCardPreviewLoaded}>
                <div>
                  <Button
                    variant={BtnVariant.TRANSPARENCY}
                    size={BtnSize.BASE}
                    onClick={onCancel}
                  >
                    <Icon
                      class={TextColor.WHITE}
                      name={'trash'}
                      size={IconSize.X24}
                    />
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  },
});
