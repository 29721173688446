import { defineComponent, PropType, computed, ref } from 'vue';
import styles from './index.css?module';
import { Text, TextColor } from '@/components/ui';

export enum TYPE {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
}

export enum VARIANT {
  BASE,
  SECONDARY,
  TRANSPARENCY,
}

export default defineComponent({
  props: {
    modelValue: {
      type: [String, Number],
    },
    id: {
      type: String,
    },
    type: {
      type: String as PropType<TYPE>,
      default: TYPE.TEXT,
    },
    variant: {
      type: Number as PropType<VARIANT>,
      default: VARIANT.BASE,
    },
    title: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    pattern: {
      type: String,
    },
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    encodeDate: {
      type: Function as PropType<(date: string) => string>,
      default: (d: string) => d,
    },
    decodeDate: {
      type: Function as PropType<(date: string) => string>,
      default: (d: string) => d,
    },
  },

  setup(props, { emit, slots }) {
    const focused = ref(false);
    const el = ref({} as HTMLElement);

    const syncModel = computed({
      get() {
        if (typeof props.modelValue === 'undefined') {
          return null;
        }
        if (typeof props.modelValue === 'number') {
          return props.modelValue > 0 ? props.modelValue : null;
        }
        return props.modelValue;
      },
      set(newVal) {
        emit('update:modelValue', newVal);
      },
    });

    const syncDateModel = computed({
      get() {
        return props.encodeDate(props.modelValue as string);
      },
      set(newVal) {
        emit('update:modelValue', props.decodeDate(newVal as string));
      },
    });

    const inputType = computed(() => {
      switch (props.type) {
        case TYPE.DATE:
          return TYPE.TEXT;
        default:
          return props.type;
      }
    });

    const variantWrapperStyle = (): string => {
      switch (props.variant) {
        case VARIANT.BASE:
          return styles.inputWrapperBase;
        case VARIANT.SECONDARY:
          return styles.inputWrapperSecondary;
        case VARIANT.TRANSPARENCY:
          return '';
      }
    };

    const variantFieldStyle = (): string => {
      switch (props.variant) {
        case VARIANT.BASE:
          return styles.inputFieldBase;
        case VARIANT.SECONDARY:
          return styles.inputFieldSecondary;
        case VARIANT.TRANSPARENCY:
          return '';
      }
    };

    const checkClosestClick = (e: TouchEvent) => {
      if (!el.value) {
        return;
      }
      if (e.target != el.value) {
        el.value?.blur();
      }
    };

    const onFocus = () => {
      focused.value = true;
      document.addEventListener('touchstart', checkClosestClick);
    };

    const onBlur = () => {
      focused.value = false;
      document.removeEventListener('touchstart', checkClosestClick);
    };

    return () => (
      <div
        class={[
          styles.inputWrapper,
          variantWrapperStyle(),
          focused.value && styles.inputWrapperFocused,
          props.hasError && styles.inputWrapperWithError,
        ]}
      >
        {slots?.leftIcon && (
          <div class={styles.inputLeftIcon}>{slots?.leftIcon?.()}</div>
        )}
        {props.title && (
          <div class={styles.inputTitle}>
            <Text color={TextColor.PRIMARY}>{props.title}</Text>
          </div>
        )}
        {props.type === TYPE.DATE && (
          <input
            type={TYPE.DATE}
            v-model={syncDateModel.value}
            class={styles.inputDatetime}
          />
        )}
        <input
          class={[styles.inputField, variantFieldStyle()]}
          v-model={syncModel.value}
          ref={el}
          id={props.id}
          type={inputType.value}
          placeholder={props.placeholder}
          pattern={props.pattern}
          min={props.min}
          max={props.max}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        {slots?.rightIcon && (
          <div class={styles.inputRightIcon}>{slots?.rightIcon?.()}</div>
        )}
      </div>
    );
  },
});
