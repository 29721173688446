export interface ITgWebAppSignInResponse {
  lang: string;
}

export interface ITgWebAppSignInRequest {
  data: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IGetProfileRequest {}

export enum GenderType {
  Male = 'male',
  FeMale = 'female',
}

export interface IProfile {
  name: string;
  age: number;
  birthday: string;
  height: number;
  weight: number;
  gender: IGender;
  desire: IDesire;
  sexOrientation: ISexOrientation;
  city: ICity;
  photos: Readonly<IPhoto[]>;
  hasSharedGeo: boolean;
}

export interface IPhoto {
  fileId: string;
  path: string;
}

export interface ICity {
  id: string;
  name: string;
}

export interface IGender {
  slug: string;
  name: string;
}

export interface ISexOrientation {
  slug: string;
  name: string;
}

export interface IDesire {
  slug: string;
  name: string;
}

export interface IPhotoData {
  fileId: string;
}

export interface ISetProfileRequest {
  name: string;
  birthday: string;
  height: number;
  weight: number;
  cityId: string;
  gender: string;
  desire: string;
  sexOrientation: string;
  photos: IPhotoData[];
}

export interface ISetLanguageResponse {
  lang: string;
}

export interface ISetLanguageRequest {
  lang: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISetOnlineRequest {}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SetOnlineResponse {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IShareLocationRequest {}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IShareLocationResponse {}
