import { computed, defineComponent, PropType } from 'vue';
import styles from './index.css?module';
import { Text, TextColor, TextSize, TextWeight } from '@/components/ui';
import { IProfile } from '@/api/auth';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  props: {
    profile: {
      type: Object as PropType<Readonly<IProfile>>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();

    const userParams = computed((): string => {
      let sexInfo = props.profile?.gender?.name || '';
      sexInfo = sexInfo.trim().toLowerCase();

      const result = [] as string[];
      if (sexInfo.length > 0) {
        result.push(sexInfo);
      }

      if (props.profile.age > 0) {
        const ageText = t('component.userInfo.ageMeasure', props.profile.age);
        result.push(`${props.profile.age} ${ageText}`);
      }

      if (props.profile.height > 0) {
        const heightMeasure = t('component.userInfo.heightMeasure');
        result.push(`${props.profile.height} ${heightMeasure}`);
      }

      return result.join(', ');
    });

    const cityName = computed((): string => {
      const cityPrefix = t('component.userInfo.cityPrefix');
      return `${cityPrefix} ${props.profile.city.name}`;
    });

    return () => (
      <div class={styles.userInfoWrapper}>
        <Text size={TextSize.H3}>{props.profile.name}</Text>
        {props.profile.city && (
          <Text
            size={TextSize.BASE}
            weight={TextWeight.LIGHT}
            color={TextColor.SECONDARY}
            class={'mt-1'}
          >
            {cityName.value}
          </Text>
        )}
        <Text
          size={TextSize.BASE}
          weight={TextWeight.LIGHT}
          color={TextColor.SECONDARY}
        >
          {userParams.value}
        </Text>
      </div>
    );
  },
});
