import parse from 'date-fns/parse';
import format from 'date-fns/format';

const DATE_FORMAT = 'dd.MM.yyyy';
const SYSTEM_DATE_FORMAT = 'yyyy-MM-dd';

export function useDateFormat() {
  function convertDateToSystemFormat(date: string): string {
    if (date.length <= 0) {
      return '';
    }

    const d = parse(date, DATE_FORMAT, new Date());
    return format(d, SYSTEM_DATE_FORMAT);
  }

  function convertSystemDateToUserFormat(date: string): string {
    if (date.length <= 0) {
      return '';
    }

    const d = parse(date, SYSTEM_DATE_FORMAT, new Date());
    return format(d, DATE_FORMAT);
  }

  return {
    convertDateToSystemFormat,
    convertSystemDateToUserFormat,
  };
}
