import { defineStore } from 'pinia';
import {
  api,
  ICity,
  IGender,
  IDesire,
  IGetValuesRequest,
  IGetValuesResponse,
  ISexOrientation,
} from '@/api/dictionary';
import { Response } from '@/api';

export const useDictionaryStore = defineStore('dictionary', () => {
  const searchCityList = async (
    query: string,
    page: number
  ): Promise<ICity[]> => {
    const resp = await api.searchCityList({ query, page });
    if (!resp.ok) {
      return [];
    }

    return resp.payload.data;
  };

  const getGenderList = async (): Promise<IGender[]> => {
    const resp = await api.getGenderList({});
    if (!resp.ok) {
      return [];
    }

    return resp.payload.data;
  };

  const getDesireList = async (): Promise<IDesire[]> => {
    const resp = await api.getDesireList({});
    if (!resp.ok) {
      return [];
    }

    return resp.payload.data;
  };

  const getSexOrientationList = async (
    gender: string
  ): Promise<ISexOrientation[]> => {
    const resp = await api.getSexOrientationList({ gender });
    if (!resp.ok) {
      return [];
    }

    return resp.payload.data;
  };

  const getValues = async (
    data: IGetValuesRequest
  ): Promise<Response<IGetValuesResponse>> => {
    return await api.getValues(data);
  };

  return {
    getValues,
    searchCityList,
    getGenderList,
    getDesireList,
    getSexOrientationList,
  };
});
