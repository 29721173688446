import { useEnv } from '@/composables/env';

interface Options {
  w: number;
  h: number;
}

export function useS3() {
  const baseUrl = prepareUrl(useEnv().s3Url());

  function prepareUrl(url: string): string {
    if (url[url.length - 1] === '/') {
      url = url.slice(0, url.length - 1);
    }
    if (url[0] === '/') {
      url = url.slice(1, url.length);
    }
    return url;
  }

  function resize(path: string, opts: Options): string {
    return `${baseUrl}/resize/${opts.w}/${opts.h}/${prepareUrl(path)}`;
  }

  function crop(path: string, opts: Options): string {
    return `${baseUrl}/crop/${opts.w}/${opts.h}/${prepareUrl(path)}`;
  }

  function original(path: string): string {
    return `${baseUrl}/${prepareUrl(path)}`;
  }

  return {
    crop,
    resize,
    original,
  };
}
