import { ref } from 'vue';

export function useSemaphore() {
  const semaphore = ref(false);

  function tryAcquire(): boolean {
    return !semaphore.value && (semaphore.value = true);
  }

  function release(): void {
    semaphore.value = false;
  }

  return {
    tryAcquire,
    release,
  };
}
