import { defineComponent, computed, PropType, ref, onMounted } from 'vue';
import styles from './index.css?module';
import {
  Button,
  BtnVariant,
  BtnSize,
  Icon,
  Text,
  TextColor,
  TextSize,
  LoaderVariant,
  LoaderSize,
  Loader,
  DESIRE_ICONS,
} from '@/components/ui';
import { IAdInfo, IPhotoMeta, IProfile } from '@/api/ad';
import { GenderType } from '@/api/auth';
import { useSemaphore, useS3 } from '@/composables';
import { ToastType, useAdStore, useCupidStore, useToast } from '@/store';
import throttle from 'lodash.throttle';
import { useI18n } from 'vue-i18n';

interface HeartIcon {
  icon: string;
  color: string;
}

export interface LazyPhoto {
  url: string;
  meta: IPhotoMeta;
  inLoad: boolean;
  isLoaded: boolean;
  containerWidth: number;
}

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<Readonly<IAdInfo>>,
      required: true,
    },
    onOpenGallery: {
      type: Function as PropType<(photos: LazyPhoto[], index: number) => void>,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const adStore = useAdStore();
    const toastStore = useToast();
    const cupidStore = useCupidStore();
    const semaphore = useSemaphore();
    const media = useS3();

    const swiperEl = ref(null);

    const profile = computed((): IProfile => {
      return props.item.profile;
    });

    const profileInfo = computed((): string => {
      const result = [] as string[];

      if (profile.value.age > 0) {
        const ageText = t('component.adCard.ageMeasure', profile.value.age);
        result.push(`${profile.value.age} ${ageText}`);
      }

      if (profile.value.height > 0) {
        const heightMeasure = t('component.adCard.heightMeasure');
        result.push(`${profile.value.height} ${heightMeasure}`);
      }

      if (profile.value.online && profile.value.online?.status?.length > 0) {
        result.push(`${profile.value.online?.status}`);
      }

      return result.join(', ');
    });

    const photos = ref(
      props.item.photos.map((p) => {
        return {
          url: media.resize(p.path, { w: 500, h: 500 }),
          meta: p.meta,
          isLoaded: false,
          containerWidth: 0,
        } as LazyPhoto;
      })
    );

    const hasPhotos = computed((): boolean => {
      return props.item.photos.length > 0;
    });

    const profileAvatar = computed(() => {
      switch (profile.value.gender) {
        case GenderType.Male:
          if (profile.value.age > 30) {
            return 'man_old';
          } else {
            return 'man';
          }
        case GenderType.FeMale:
          return 'woman';
        default:
          return '';
      }
    });

    const cityName = computed((): string => {
      const cityPrefix = t('component.adCard.cityPrefix');
      return `${cityPrefix} ${props.item.city.name}`;
    });

    const distanceName = computed((): string => {
      return props.item.distance?.value;
    });

    const HeartIcon = computed((): HeartIcon => {
      if (props.item.hasLike) {
        return { icon: 'heart-solid', color: 'text-textHighlight-danger' };
      }
      return { icon: 'heart', color: '' };
    });

    const desireIcon = computed((): string => {
      return DESIRE_ICONS[props.item.profile.desire.slug] ?? '';
    });

    const onLike = async () => {
      if (props.item.hasLike || !semaphore.tryAcquire()) {
        return;
      }

      const resp = await cupidStore.like(props.item.profile.userId);
      if (resp.ok) {
        adStore.setAdLike(props.item.id);
      } else {
        toastStore.add(ToastType.ERROR, resp.message);
      }

      semaphore.release();
    };

    const photoDimensionClass = (photo: LazyPhoto): string => {
      if (photo.meta.height > photo.meta.width) {
        return styles.AdPhotoContainerHigh;
      }
      return styles.AdPhotoContainerWide;
    };

    const lazyLoadImage = (photo: LazyPhoto) => {
      photo.inLoad = true;

      const image = new Image();
      image.onload = () => {
        photo.isLoaded = true;
      };
      image.src = photo.url;
    };

    const onScroll = throttle((e: Event) => {
      const target = e.target as HTMLElement;

      for (let i = 1; i < target.children.length; i++) {
        const photo = photos.value[i - 1];
        if (!photo || photo.isLoaded || photo.inLoad) {
          continue;
        }

        const child = target.children[i] as HTMLElement;
        if (isInViewport(child)) {
          lazyLoadImage(photo);
        }
      }
    }, 25);

    function isInViewport(element: HTMLElement): boolean {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    const openPhoto = (index: number) => {
      emit('openGallery', photos.value, index);
    };

    onMounted(() => {
      if (swiperEl.value !== null) {
        const swiper = swiperEl.value as HTMLElement;
        const firstChild = swiper.firstChild as HTMLElement;
        const bounding = firstChild.getBoundingClientRect();

        const photosWithWidth = [] as LazyPhoto[];
        photos.value.forEach((photo) => {
          photosWithWidth.push({
            ...photo,
            containerWidth: bounding.width,
          } as LazyPhoto);
        });

        firstChild.style.width = bounding.width + 'px';
        firstChild.style.minWidth = bounding.width + 'px';
        photos.value = photosWithWidth;
      }
    });

    return () => (
      <div class={styles.Container}>
        <div class={styles.Swiper} ref={swiperEl} onScroll={onScroll}>
          <div class={styles.AdCard}>
            <div
              class={[
                styles.AdCardContainer,
                hasPhotos.value && styles.AdCardContainerWithPhoto,
              ]}
            >
              <div>
                <div class={styles.AdCardHeader}>
                  <div class={'bg-bgField text-3xl'}>
                    <img
                      class={'w-8 h-8'}
                      src={require(`@/assets/images/${profileAvatar.value}.png`)}
                      alt=""
                    />
                  </div>
                  <div class={'flex flex-col'}>
                    <Text size={TextSize.H4}>{profile.value.name}</Text>
                    <div class={'flex flex-row'}>
                      <Text size={TextSize.CAPTION} color={TextColor.SECONDARY}>
                        {profileInfo.value}
                      </Text>
                    </div>
                    {props.item.profile.desire && (
                      <div class={'flex flex-row items-center'}>
                        <Text
                          size={TextSize.CAPTION}
                          color={TextColor.SECONDARY}
                        >
                          {props.item.profile.desire.name}
                        </Text>
                        <img
                          class={'w-4 h-4 ml-1'}
                          src={require(`@/assets/images/${desireIcon.value}.png`)}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div class={styles.AdTextContainer}>
                  {props.item.description}
                </div>
              </div>
              <div class={styles.AdCardFooter}>
                <div class={'flex flex-row text-textSecondary'}>
                  <div class={'truncate max-w-[14rem]'}>{cityName.value}</div>
                  {distanceName.value?.length > 0 && (
                    <div>, {distanceName.value}</div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {photos.value.map((photo, index) => {
            if (photo.containerWidth <= 0) {
              return;
            }
            return (
              <div
                key={`photo-${index}`}
                class={[styles.AdCard, styles.AdCardWithPhoto]}
                style={`width:${photo.containerWidth}px;min-width:${photo.containerWidth}px`}
                onClick={() => openPhoto(index)}
              >
                <div
                  class={[styles.AdPhotoContainer, photoDimensionClass(photo)]}
                  style={
                    photo.isLoaded
                      ? `background-image: url("${photo.url}")`
                      : ''
                  }
                ></div>
                {!photo.isLoaded && (
                  <Loader
                    class={'absolute inset-y-1/3 left-0 right-0 mx-auto'}
                    variant={LoaderVariant.SIMPLE}
                    size={LoaderSize.XL}
                  />
                )}
              </div>
            );
          })}
        </div>
        <div class={styles.LikeIconWrapper}>
          <Button
            variant={BtnVariant.TRANSPARENCY}
            size={BtnSize.INHERIT}
            onClick={onLike}
          >
            <Icon
              class={['text-2xl', HeartIcon.value.color]}
              name={HeartIcon.value.icon}
            />
          </Button>
        </div>
      </div>
    );
  },
});
