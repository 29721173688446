export interface ISearchCityListRequest {
  query: string;
  page: number;
}

export interface ICountry {
  code: string;
  name: string;
}

export interface ICity {
  id: string;
  name: string;
  country: ICountry;
}

export interface ISearchCityListResponse {
  data: ICity[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IGetGenderListRequest {}

export interface IGender {
  slug: string;
  name: string;
}

export interface IGetGenderListResponse {
  data: IGender[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IGetDesireListRequest {}

export interface IDesire {
  slug: string;
  name: string;
}

export interface IGetDesireListResponse {
  data: IDesire[];
}

export interface IGetSexOrientationListRequest {
  gender: string;
}

export interface ISexOrientation {
  slug: string;
  name: string;
}

export interface IGetSexOrientationListResponse {
  data: ISexOrientation[];
}

export interface IGetValuesRequest {
  cityId?: string;
  gender?: string;
  desire?: string;
  sexOrientation?: string;
  distance?: string;
}

export interface IDistance {
  slug: string;
  name: string;
}

export interface IGetValuesResponse {
  city: ICity;
  gender: IGender;
  desire: IDesire;
  sexOrientation: ISexOrientation;
  distance: IDistance;
}

export enum DesireType {
  SeriousDesireType = 'EIS',
  OpenRelationshipDesireType = 'ORP',
  FWBDesireType = 'FWB',
  ONSDesireType = 'ONS',
  VirtDesireType = 'VRT',
}

export enum DistanceType {
  Nearby = 'NEARBY',
  Neighbors = 'NEIGHBORS',
  NotFarAway = 'NOT_FAR_AWAY',
  OnTheHorizon = 'ON_THE_HORIZON',
}
