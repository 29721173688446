import { defineComponent, ref } from 'vue';
import styles from './index.css?module';
import { useEndOfScroll } from '@/composables';

export default defineComponent({
  props: {
    checkEndOfScroll: {
      type: Boolean,
    },
    endOfScrollThreshold: {
      type: Number,
      default: 800,
    },
    onEndOfScroll: {
      type: Function,
    },
  },

  setup(props, { slots, emit }) {
    const { isEndOfScroll, checkEndOfScroll } = useEndOfScroll(
      props.endOfScrollThreshold
    );

    const el = ref({} as HTMLElement);

    const onScroll = () => {
      if (!el.value) {
        return;
      }
      checkEndOfScroll(el.value);
      if (isEndOfScroll.value) {
        emit('endOfScroll');
      }
    };

    return () => (
      <div
        class={styles.Container}
        ref={el}
        onScroll={props.checkEndOfScroll ? onScroll : undefined}
      >
        {() => slots?.default?.()}
      </div>
    );
  },
});
