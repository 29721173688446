import { defineComponent, ref, PropType, onMounted } from 'vue';
import {
  Input,
  InputGroup,
  GroupItem,
  InputType,
  InputVariant,
  Text,
  TextColor,
  TextSize,
  Container,
} from '@/components/ui';
import { useAppStore } from '@/store';
import { useI18n } from 'vue-i18n';

export interface RangeValue {
  name: string;
  from: number;
  to: number;
}

export default defineComponent({
  props: {
    modelValue: {
      type: Object as PropType<RangeValue>,
    },
    description: {
      type: String,
    },
    onApply: {
      type: Function as PropType<(v: RangeValue) => void>,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const appStore = useAppStore();

    const ageModel = ref({
      from: props.modelValue?.from,
      to: props.modelValue?.to,
      name: props.modelValue?.name,
    } as RangeValue);

    const updateMainButton = () => {
      appStore.showMainButton(t('component.filterRange.mainButton'), () => {
        emit('apply', ageModel.value);
      });
    };

    onMounted(() => {
      updateMainButton();
    });

    return () => (
      <Container class={'mt-8'}>
        <InputGroup>
          <GroupItem>
            <Input
              type={InputType.NUMBER}
              variant={InputVariant.TRANSPARENCY}
              title={t('component.filterRange.from')}
              pattern="[0-9]*"
              v-model={ageModel.value.from}
            />
          </GroupItem>
          <GroupItem>
            <Input
              type={InputType.NUMBER}
              variant={InputVariant.TRANSPARENCY}
              title={t('component.filterRange.to')}
              pattern="[0-9]*"
              v-model={ageModel.value.to}
            />
          </GroupItem>
        </InputGroup>
        {props.description && (
          <div class={'mt-1 ml-4'}>
            <Text color={TextColor.SECONDARY_DARK} size={TextSize.CAPTION}>
              {props.description}
            </Text>
          </div>
        )}
      </Container>
    );
  },
});
