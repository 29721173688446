import { computed, defineComponent, PropType } from 'vue';
import styles from './index.css?module';
import { Text, TextSize, TextWeight, TextColor } from '@/components/ui';
import { useS3 } from '@/composables';

export enum VARIANT {
  PRIMARY,
  PRIMARY_LIGHT,
}

export default defineComponent({
  props: {
    variant: {
      type: Number as PropType<VARIANT>,
      default: VARIANT.PRIMARY,
    },
    name: {
      type: String,
    },
    photo: {
      type: String,
    },
    onClick: {
      type: Function,
    },
  },

  setup(props, { slots, emit }) {
    const media = useS3();
    const initials = computed((): string => {
      if (props.name) {
        return props.name[0].toUpperCase();
      }
      return '';
    });

    const variantStyle = (): string => {
      switch (props.variant) {
        case VARIANT.PRIMARY:
          return styles.avatarPrimary;
        case VARIANT.PRIMARY_LIGHT:
          return styles.avatarPrimaryLight;
      }
    };

    const avatarStyles = computed((): string => {
      if ((props.photo?.length ?? 0) <= 0) {
        return '';
      }
      return `background-image: url("${media.original(
        props.photo as string
      )}")`;
    });

    return () => (
      <div
        class={[styles.avatar, variantStyle()]}
        style={avatarStyles.value}
        onClick={() => emit('click')}
      >
        <Text
          size={TextSize.H1}
          color={TextColor.WHITE}
          weight={TextWeight.BOLD}
        >
          {!slots?.default && initials.value}

          {slots?.default && (
            <div class={styles.inputRightIcon}>{slots?.default?.()}</div>
          )}
        </Text>
      </div>
    );
  },
});
