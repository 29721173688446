import { Plugin } from './types';
import { useTelegramWebApp } from '@/plugins/telegram/useWebApp';
import { markRaw } from 'vue-demi';

const SCRIPT = 'https://telegram.org/js/telegram-web-app.js';

export function createTelegramWebApp(): Plugin {
  return markRaw({
    install() {
      const found = <HTMLAnchorElement | null>(
        document.querySelector('script[src="' + SCRIPT + '"]')
      );
      if (!found) {
        const el = document.createElement('script');
        el.type = 'text/javascript';
        el.async = true;
        el.src = SCRIPT;

        el.addEventListener('load', () => {
          const tgApp = useTelegramWebApp();
          tgApp._setScriptLoaded();
        });

        document.head.appendChild(el);
      }
    },
  });
}
