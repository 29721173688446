import { api as client, Response } from '@/api';
import { ILikeRequest, ILikeResponse } from '@/api/cupid/types';

export default {
  like: async (payload: ILikeRequest): Promise<Response<ILikeResponse>> => {
    return await client.post<ILikeResponse, ILikeRequest>(
      `/api/cupid/${payload.userId}`,
      payload
    );
  },
};
