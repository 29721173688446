import { createApp } from 'vue';
import { createPinia } from 'pinia';
import Vue3Lottie from 'vue3-lottie';
import { createI18n } from 'vue-i18n';
import { useAppStore } from '@/store/app';
import { createTelegramWebApp } from '@/plugins';
import { i18nRuPluralize } from '@/composables';
import { useFetchWithAuth } from '@/api/middleware/auth';
import App from './App';
import router from './router';

// app locales
import en from './locales/en.json';
import ru from './locales/ru.json';

// app styles
import '@/assets/styles/tailwind.css';
import '@/assets/styles/main.scss';

// photoswipe styles
import 'photoswipe/style.css';

// Create app
const pinia = createPinia();
const tgWebApp = createTelegramWebApp();

const i18n = createI18n({
  legacy: false,
  locale: 'ru',
  fallbackLocale: 'en',
  messages: { en, ru },
  pluralRules: {
    ru: i18nRuPluralize,
  },
});

const app = createApp(App)
  .use(tgWebApp)
  .use(pinia)
  .use(i18n)
  .use(Vue3Lottie)
  .use(router);

// Configure
window.fetch = useFetchWithAuth(useAppStore().authMiddleware);

app.mount('#app');
