import { ref } from 'vue';

export function useEndOfScroll(threshold: number) {
  const isEndOfScroll = ref(false);

  const onScroll = () => {
    checkEndOfScroll(document.documentElement);
  };

  const checkEndOfScroll = (scrolledEl: HTMLElement) => {
    const endOfPageHeight = scrolledEl.scrollHeight - threshold;
    const currentScrollHeight = scrolledEl.scrollTop + window.innerHeight;

    const result = currentScrollHeight >= endOfPageHeight;
    if (result != isEndOfScroll.value) {
      isEndOfScroll.value = result;
    }
  };

  const onScrollMounted = () => {
    window.addEventListener('scroll', onScroll);
  };

  const onScrollUnmounted = () => {
    window.removeEventListener('scroll', onScroll);
  };

  return {
    isEndOfScroll,
    checkEndOfScroll,
    onScrollMounted,
    onScrollUnmounted,
  };
}
