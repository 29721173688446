import { defineComponent, ref, watch, PropType, onMounted } from 'vue';
import styles from './index.css?module';
import {
  Center,
  Icon,
  Input,
  InputVariant,
  Loader,
  LoaderSize,
} from '@/components/ui';
import DictionaryItem from '@/components/ui/dictionary/item/DictionaryItem';
import debounce from 'lodash.debounce';
import { useI18n } from 'vue-i18n';

export interface DictionaryValue {
  key: string;
  name: string;
  icon?: string;
  caption?: string;
  captionImg?: string;
}

export default defineComponent({
  props: {
    selectedItem: {
      type: Object as PropType<DictionaryValue>,
    },
    loadDictionaryFn: {
      type: Function as PropType<() => Promise<DictionaryValue[]>>,
      required: true,
    },
    onChecked: {
      type: Function as PropType<(item: DictionaryValue) => void>,
    },
  },

  setup(props, { slots, emit }) {
    const { t } = useI18n();
    const searchQuery = ref('');
    const listIsReady = ref(false);

    const items = ref([] as DictionaryValue[]);
    const filteredItems = ref([] as DictionaryValue[]);

    const onFilterList = debounce((query: string) => {
      if (query.trim().length <= 2) {
        filteredItems.value = items.value;
        return;
      }
      const search = query.trim().toLowerCase();
      filteredItems.value = items.value.filter((x) => {
        return x.name.toLowerCase().startsWith(search);
      });
    }, 300);

    const onOpenList = async () => {
      items.value = await props.loadDictionaryFn();
      filteredItems.value = items.value;
    };

    const onChecked = (item: DictionaryValue) => {
      emit('checked', item);
    };

    watch(searchQuery, () => {
      onFilterList(searchQuery.value);
    });

    onMounted(async () => {
      await onOpenList();
      listIsReady.value = true;
    });

    return () => (
      <div class={styles.selectListWrapper}>
        {!listIsReady.value ? (
          <Center>
            <Loader size={LoaderSize.XL} />
          </Center>
        ) : (
          <>
            <div class={'fixed w-full bg-bgApp'}>
              <div class={'py-2 px-4'}>
                <Input
                  variant={InputVariant.SECONDARY}
                  placeholder={t('component.dictionary.search')}
                  v-model={searchQuery.value}
                >
                  {{
                    leftIcon: () => (
                      <Icon class={'text-lg'} name={'magnifying-glass'} />
                    ),
                  }}
                </Input>
              </div>
            </div>

            <div class={styles.selectList}>
              {filteredItems.value.map((item) => {
                return (
                  <DictionaryItem
                    key={`dictionary-item-${item.key}`}
                    item={item}
                    isChecked={item.key === props?.selectedItem?.key}
                    onChecked={onChecked}
                  />
                );
              })}
            </div>
            {items.value.length <= 0 && slots?.emptyList?.()}
          </>
        )}
      </div>
    );
  },
});
