import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import {
  Text,
  TextColor,
  Container,
  TextArea,
  Button,
  BtnVariant,
  BtnSize,
  Center,
  Loader,
  LoaderSize,
  Avatar,
  UserInfo,
} from '@/components/ui';
import {
  useAppStore,
  useAuthStore,
  useAdStore,
  ToastType,
  useToast,
} from '@/store';
import { useRouteBuilder } from '@/composables';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  setup: () => {
    const { t } = useI18n();
    const router = useRouter();
    const appStore = useAppStore();
    const authStore = useAuthStore();
    const adStore = useAdStore();
    const toastStore = useToast();
    const routeBuilder = useRouteBuilder();
    const initialized = ref(false);
    const adModel = ref('');

    const profilePhoto = computed((): string => {
      if ((authStore.profile?.photos?.length ?? 0) <= 0) {
        return '';
      }
      return authStore.profile?.photos[0].path as string;
    });

    const profileName = computed((): string => {
      if ((authStore.profile?.photos?.length ?? 0) <= 0) {
        return authStore.profile?.name as string;
      }
      return '';
    });

    const updateBackButton = () => {
      appStore.showBackButton(() => {
        router.push(routeBuilder.adsList('0'));
      });
    };

    const updateMainButton = () => {
      if (adModel.value.length <= 0) {
        appStore.hideMainButton();
        return;
      }

      let title: string;
      let successMsg: string;
      let isActive = adStore.ad?.isActive ?? false;
      const wasChanged = adModel.value != adStore.ad?.description;

      if (wasChanged) {
        title = t('views.user.detail.saveMainBtn');
        successMsg = t('views.user.detail.toastSaved');
      } else {
        title = adStore.ad?.isActive
          ? t('views.user.detail.unPublishMainBtn')
          : t('views.user.detail.publishMainBtn');
        successMsg = adStore.ad?.isActive
          ? t('views.user.detail.toastUnPublished')
          : t('views.user.detail.toastPublished');

        isActive = !adStore.ad?.isActive ?? false;
      }

      appStore.showMainButton(title, async () => {
        const errors = await adStore.updateAd(adModel.value, isActive);
        if (errors.length <= 0) {
          updateMainButton();
          toastStore.add(ToastType.SUCCESS, successMsg);
          return;
        }

        const msg = errors.map((err) => err.description).join('. ');
        appStore.showAlert(msg);
      });
    };

    const updateAdModel = () => {
      adModel.value = adStore.ad?.description ?? '';
    };

    const onEditProfile = async () => {
      await router.push(routeBuilder.editProfile());
    };

    onMounted(async () => {
      if (typeof authStore.profile === 'undefined') {
        const profileResp = await authStore.loadProfile();
        if (!profileResp.ok) {
          return;
        }
      }

      if (typeof adStore.ad === 'undefined') {
        const adResp = await adStore.loadAd();
        if (!adResp.ok) {
          return;
        }
      }

      updateAdModel();
      updateBackButton();
      updateMainButton();
      initialized.value = true;
    });

    watch(adModel, () => {
      updateMainButton();
    });

    return () => (
      <>
        {!initialized.value || !authStore.profile ? (
          <Center>
            <Loader size={LoaderSize.XL} />
          </Center>
        ) : (
          <Container>
            <div class={'flex flex-row justify-between'}>
              <div></div>
              <div>
                <Button
                  variant={BtnVariant.TRANSPARENCY}
                  size={BtnSize.INHERIT}
                  class={'py-1'}
                  onClick={onEditProfile}
                >
                  <Text color={TextColor.HIGHLIGHT}>
                    {t('views.user.detail.changeBtn')}
                  </Text>
                </Button>
              </div>
            </div>

            <div class={'flex flex-col items-center pb-8'}>
              <Avatar name={profileName.value} photo={profilePhoto.value} />
              <UserInfo class={'mt-4'} profile={authStore.profile} />
            </div>

            <div>
              <TextArea
                v-model={adModel.value}
                maxlength={300}
                placeholder={t('views.user.detail.adPlaceholder')}
              />
            </div>
          </Container>
        )}
      </>
    );
  },
});
