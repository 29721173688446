import { api as client, Response } from '@/api';
import {
  ITgWebAppSignInRequest,
  ITgWebAppSignInResponse,
  ISetProfileRequest,
  IProfile,
  IGetProfileRequest,
  ISetLanguageRequest,
  ISetLanguageResponse,
  ISetOnlineRequest,
  SetOnlineResponse,
  IShareLocationRequest,
  IShareLocationResponse,
} from '@/api/auth/types';

export default {
  tgWebAppSignIn: async (
    payload: ITgWebAppSignInRequest
  ): Promise<Response<ITgWebAppSignInResponse>> => {
    return await client.post<ITgWebAppSignInResponse, ITgWebAppSignInRequest>(
      '/api/auth/v1/tg-web-app/sign-in',
      payload
    );
  },

  getProfile: async (
    payload: IGetProfileRequest
  ): Promise<Response<IProfile>> => {
    return await client.get<IProfile, IGetProfileRequest>(
      '/api/auth/v1/profile',
      payload
    );
  },

  setProfile: async (
    payload: ISetProfileRequest
  ): Promise<Response<IProfile>> => {
    return await client.post<IProfile, ISetProfileRequest>(
      '/api/auth/v1/profile',
      payload
    );
  },

  setOnline: async (
    payload: ISetOnlineRequest
  ): Promise<Response<SetOnlineResponse>> => {
    return await client.post<SetOnlineResponse, ISetOnlineRequest>(
      '/api/auth/v1/online',
      payload
    );
  },

  shareLocation: async (
    payload: IShareLocationRequest
  ): Promise<Response<IShareLocationResponse>> => {
    return await client.post<IShareLocationResponse, IShareLocationRequest>(
      '/api/auth/v1/share-location',
      payload
    );
  },

  setLanguage: async (
    payload: ISetLanguageRequest
  ): Promise<Response<ISetLanguageResponse>> => {
    return await client.put<ISetLanguageResponse, ISetLanguageRequest>(
      '/api/auth/v1/language',
      payload
    );
  },
};
