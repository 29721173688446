import { useEnv } from '@/composables';

const { Response: originalResponse } = window;
type HttpMethod = 'get' | 'post' | 'put' | 'patch' | 'head' | 'delete';

export interface RequestErrorDetails {
  field: string;
  description: string;
}

interface RequestError {
  code: number;
  message: string;
  details: RequestErrorDetails[];
}

interface ResponseSuccess<P = void> {
  ok: true;
  payload: P;
}

interface ResponseError {
  ok: false;
  code: number;
  message: string;
  error: RequestErrorDetails[];
}

export type Response<P = void> = ResponseSuccess<P> | ResponseError;

class ApiManager {
  public baseUrl = '';
  public language = 'ru';

  constructor(baseUrl: string) {
    if (baseUrl[baseUrl.length - 1] === '/') {
      baseUrl = baseUrl.slice(0, baseUrl.length - 1);
    }

    this.baseUrl = baseUrl;
  }

  public setLanguageCode(code: string) {
    this.language = code;
  }

  private buildUrl(path: string): string {
    if (path[0] === '/') {
      path = path.slice(1);
    }

    return `${this.baseUrl}/${path}`;
  }

  public async request<R = void, P = void>(
    url: string,
    method: HttpMethod,
    payload?: P,
    options?: RequestInit
  ): Promise<Response<R>> {
    const headers = new Headers(options?.headers || {});
    if (!headers.has('Content-Type')) {
      headers.append('Content-Type', 'application/json');
    }

    if (this.language.length > 0) {
      headers.append('X-Language', this.language);
    }

    let body: BodyInit | null = null;
    if (['post', 'put', 'patch'].includes(method)) {
      if (payload instanceof ArrayBuffer) {
        body = payload;
      } else {
        body = JSON.stringify(payload);
      }
    }

    if (['get'].includes(method)) {
      const query = new URLSearchParams(JSON.parse(JSON.stringify(payload)));
      url = url + `?${query.toString()}`;
    }

    try {
      const response = await fetch(this.buildUrl(url), {
        method: method,
        mode: 'cors' as RequestMode,
        credentials: 'include',
        headers: headers,
        body: body,
        signal: options?.signal,
      });

      let data = <R>{};
      if (response.body !== null) {
        data = (await response.json()) as R;
      }

      return {
        ok: true,
        payload: data,
      };
    } catch (errResp) {
      if (errResp instanceof originalResponse) {
        let error = <RequestError>{};
        if (errResp.body !== null) {
          error = (await errResp.json()) as RequestError;

          return {
            ok: false,
            code: error.code,
            message: error.message,
            error: error.details?.map(({ description, field }) => ({
              description,
              field,
            })),
          };
        }

        return {
          ok: false,
          code: errResp.status,
          message: '',
          error: [],
        };
      }

      return {
        ok: false,
        code: 500,
        message: '',
        error: [],
      };
    }
  }

  public async get<R = void, P = void>(
    url: string,
    payload?: P,
    options?: RequestInit
  ) {
    return await this.request<R, P>(url, 'get', payload, options);
  }

  public async post<R = void, P = void>(
    url: string,
    payload?: P,
    options?: RequestInit
  ) {
    return await this.request<R, P>(url, 'post', payload, options);
  }

  public async put<R = void, P = void>(
    url: string,
    payload?: P,
    options?: RequestInit
  ) {
    return await this.request<R, P>(url, 'put', payload, options);
  }

  public async delete<R = void, P = void>(
    url: string,
    payload?: P,
    options?: RequestInit
  ) {
    return await this.request<R, P>(url, 'delete', payload, options);
  }

  public async patch<R = void, P = void>(
    url: string,
    payload?: P,
    options?: RequestInit
  ) {
    return await this.request<R, P>(url, 'patch', payload, options);
  }
}

export const api = new ApiManager(useEnv().backendUrl());
