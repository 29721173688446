import { defineComponent } from 'vue';
import styles from './index.css?module';

export default defineComponent({
  setup: (props, { slots }) => {
    return () => (
      <div class={styles.inputGroupWrapper}>{slots?.default?.()}</div>
    );
  },
});
