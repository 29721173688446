import { themes } from './index';

export interface ITheme {
  [key: string]: string;
}

export interface IThemes {
  [key: string]: ITheme;
}

export interface IMappedTheme {
  [key: string]: string | null;
}

export const mapTheme = (variables: ITheme): IMappedTheme => {
  return {
    '--color-bgApp': variables.bgApp || '',

    '--color-bgPrimary': variables.bgPrimary || '',
    '--color-bgSecondary': variables.bgSecondary || '',
    '--color-bgSecondaryLight': variables.bgSecondaryLight || '',

    '--color-textPrimary': variables.textPrimary || '',
    '--color-textWhite': variables.textWhite || '',
    '--color-textSecondary': variables.textSecondary || '',
    '--color-textSecondaryDark': variables.textSecondaryDark || '',

    '--color-textHighlight': variables.textHighlight || '',
    '--color-textHighlightDanger': variables.textHighlightDanger || '',

    '--color-bgField': variables.bgField || '',
    '--color-bgFieldSecondary': variables.bgFieldSecondary || '',
  };
};

export const applyTheme = (theme: ITheme): void => {
  const themeObject: IMappedTheme = mapTheme(theme);
  if (!themeObject) return;

  const root = document.documentElement;

  Object.keys(themeObject).forEach((property) => {
    if (property === 'name') {
      return;
    }

    root.style.setProperty(property, themeObject[property]);
  });
};

export const extendTheme = (theme: string, newTheme: ITheme): void => {
  const curTheme = themes[theme];
  const extendedTheme = extend(curTheme, newTheme);

  const themeObject: IMappedTheme = mapTheme(extendedTheme);
  if (!themeObject) return;

  const root = document.documentElement;

  Object.keys(themeObject).forEach((property) => {
    if (property === 'name') {
      return;
    }

    root.style.setProperty(property, themeObject[property]);
  });
};

export const extend = (extending: ITheme, newTheme: ITheme): ITheme => {
  return { ...extending, ...newTheme };
};
