const { fetch: originalFetch } = window;

export const useFetchWithAuth =
  (authorize: () => Promise<boolean>) =>
  async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
    const response = await originalFetch(input, init);
    if (response.ok) {
      return response;
    }
    if (!response.ok && response.status !== 401) {
      return Promise.reject(response);
    }

    if (!canRefreshAuth(input)) {
      return Promise.reject(response);
    }

    const isAuthorized = await authorize();
    if (!isAuthorized) {
      return Promise.reject(response);
    }

    const repeatResp = await originalFetch(input, init);
    if (repeatResp.ok) {
      return repeatResp;
    }

    return Promise.reject(repeatResp);
  };

const canRefreshAuth = (input: RequestInfo): boolean => {
  if (typeof input === 'string') {
    return !input.includes('sign-in');
  }
  return false;
};
