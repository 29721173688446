import { defineComponent, TransitionGroup } from 'vue';
import styles from './index.css?module';
import {
  Icon,
  Text,
  TextColor,
  Button,
  BtnVariant,
  BtnSize,
} from '@/components/ui';
import { useToast, Toast, ToastType } from '@/store';

export default defineComponent({
  setup: () => {
    const toastStore = useToast();

    const getIconName = (type: ToastType): string => {
      switch (type) {
        case ToastType.ERROR:
          return 'warning';
        default:
          return 'info';
      }
    };

    const getIconColor = (type: ToastType): string => {
      switch (type) {
        case ToastType.ERROR:
          return styles.noticeWarnIcon;
        default:
          return styles.noticeInfoIcon;
      }
    };

    const onDelete = (notice: Toast) => {
      toastStore.clear(notice);
    };

    return () => (
      <TransitionGroup
        enterActiveClass={styles.enterActive}
        leaveActiveClass={styles.leaveActive}
        enterFromClass={styles.enterFrom}
        leaveToClass={styles.leaveTo}
        name="list"
      >
        {toastStore.list.map((notice: Toast) => {
          return (
            <div key={`notify-${notice.id}`} class={styles.noticeWrapper}>
              <div class={styles.noticeWrapperText}>
                <Icon
                  class={[styles.noticeIcon, getIconColor(notice.type)]}
                  name={getIconName(notice.type)}
                />
                <Text class={'ps-2'} color={TextColor.SECONDARY}>
                  {notice.message}
                </Text>
              </div>
              <div class={styles.noticeWrapperIcon}>
                <Button
                  variant={BtnVariant.TRANSPARENCY}
                  size={BtnSize.SMALL}
                  onClick={() => onDelete(notice)}
                >
                  <Icon class={styles.noticeActionIcon} name={'close'} />
                </Button>
              </div>
            </div>
          );
        })}
      </TransitionGroup>
    );
  },
});
