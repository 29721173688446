import { api, IFile } from '@/api/disk';

interface UploadFile {
  file: File;
  signal?: AbortSignal;
}

export interface UploadError {
  maximumSizeExceeded: boolean;
}

export class Uploader {
  private file: UploadFile;
  private tries: number;
  private delayMs: number;

  constructor(file: File, signal?: AbortSignal) {
    this.tries = 10;
    this.delayMs = 1000;
    this.file = this.prepareFile(file, signal);
  }

  setTries(val: number) {
    this.tries = val;
    return this;
  }

  setDelayMs(val: number) {
    this.delayMs = val;
    return this;
  }

  prepareFile(file: File, signal?: AbortSignal): UploadFile {
    return {
      file: file,
      signal: signal,
    };
  }

  upload(): Promise<IFile> {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = async () => {
        if (reader.result) {
          const arrayBuffer: ArrayBuffer = reader.result as ArrayBuffer;

          const response = await api.upload(this.file.file.name, arrayBuffer, {
            signal: this.file.signal,
          });

          if (response.ok) {
            this.deferredResult(
              response.payload.taskId,
              this.tries,
              this.delayMs
            )
              .then((f: IFile) => resolve(f))
              .catch(() => reject({} as UploadError));
          } else {
            reject({ maximumSizeExceeded: response.code == 8 } as UploadError);
          }
        } else {
          reject({} as UploadError);
        }
      };

      reader.readAsArrayBuffer(this.file.file);
    });
  }

  deferredResult(
    taskId: string,
    tries: number,
    delayMs: number
  ): Promise<IFile> {
    return new Promise((resolve, reject) => {
      const interval = setInterval(async () => {
        try {
          const response = await api.uploadStat(taskId);
          if (response.ok && response.payload.state === 'SUCCESS') {
            resolve(response.payload.file);
            clearInterval(interval);
          } else if (response.ok && response.payload.state === 'FAILURE') {
            reject();
            clearInterval(interval);
          }
        } catch (error) {
          console.error('upload', error);
        }

        if (tries <= 0) {
          reject();
          clearInterval(interval);
        }
        tries--;
      }, delayMs);
    });
  }
}
