import { defineStore } from 'pinia';

export const useCloudStore = defineStore('cloud', () => {
  const setItem = (key: string, value: string) => {
    localStorage.setItem(key, value);
  };

  const getItem = (code: string): string | null => {
    return localStorage.getItem(code);
  };

  return {
    setItem,
    getItem,
  };
});
