import { defineComponent, PropType, ref } from 'vue';
import styles from './index.css?module';
import {
  DictionaryValue,
  Icon,
  Text,
  TextColor,
  TextSize,
} from '@/components/ui';

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<DictionaryValue>,
      required: true,
    },
    isChecked: {
      type: Boolean,
      required: true,
    },
    onChecked: {
      type: Function as PropType<(item: DictionaryValue) => void>,
      required: true,
    },
  },

  setup(props) {
    const isActive = ref(false);
    const onTouchStart = () => {
      isActive.value = true;
    };
    const onTouchEnd = () => {
      isActive.value = false;
    };

    return () => (
      <div
        class={[
          styles.selectListItem,
          isActive.value && styles.selectListItemActive,
        ]}
        onClick={() => props.onChecked(props.item)}
        onTouchstart={onTouchStart}
        onTouchend={onTouchEnd}
      >
        <div class={styles.selectListItemIcon}>
          {props.item.icon && (
            <img
              class={'w-5 h-5 inline mr-2'}
              src={require(`@/assets/images/${props.item.icon}.png`)}
              alt=""
            />
          )}
        </div>
        <div
          class={[
            styles.selectListItemContent,
            !props.item.caption && styles.selectListItemContentBig,
          ]}
        >
          <div class={'flex flex-col'}>
            <div>
              <Text size={TextSize.BASE}>{props.item.name}</Text>
            </div>
            {props.item.caption && (
              <div class={'flex flex-row'}>
                <Text size={TextSize.CAPTION} color={TextColor.SECONDARY}>
                  {props.item.caption}
                </Text>
                {props.item.captionImg && (
                  <img
                    class={'w-5 h-5 ml-2'}
                    src={require(`@/assets/images/${props.item.captionImg}.png`)}
                    alt=""
                  />
                )}
              </div>
            )}
          </div>
          <div class={styles.selectListCheckedWrapper}>
            {props.isChecked && (
              <Icon class={styles.selectListChecked} name={'check'} />
            )}
          </div>
        </div>
      </div>
    );
  },
});
