import { defineComponent, ref, watch, onMounted, PropType } from 'vue';
import styles from './index.css?module';
import {
  Center,
  Icon,
  Input,
  InputVariant,
  Loader,
  LoaderSize,
  DictionaryValue,
} from '@/components/ui';
import DictionaryItem from '@/components/ui/dictionary/item/DictionaryItem';
import debounce from 'lodash.debounce';
import { useEndOfScroll } from '@/composables';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  props: {
    selectedItem: {
      type: Object as PropType<DictionaryValue>,
    },
    defaultItem: {
      type: Object as PropType<DictionaryValue>,
    },
    loadDictionaryFn: {
      type: Function as PropType<
        (q: string, page: number) => Promise<DictionaryValue[]>
      >,
      required: true,
    },
    onChecked: {
      type: Function as PropType<(item: DictionaryValue) => void>,
    },
  },

  setup(props, { slots, emit }) {
    const { t } = useI18n();
    const wrapperEl = ref(null);
    const { isEndOfScroll, checkEndOfScroll } = useEndOfScroll(200);

    const searchQuery = ref('');
    const page = ref(1);
    const hasMore = ref(true);

    const listIsReady = ref(false);
    const items = ref([] as DictionaryValue[]);

    let needSearch = false;
    const onFilterList = debounce(async (query: string) => {
      page.value = 1;
      if (query.trim().length <= 2) {
        if (needSearch) {
          needSearch = false;
          await onLoadList('');
        }
        return;
      }
      needSearch = true;
      await onLoadList();
    }, 300);

    const onLoadList = async (q?: string) => {
      const data = await props.loadDictionaryFn(
        q ?? searchQuery.value,
        page.value
      );

      if (props.defaultItem) {
        items.value = [props.defaultItem, ...data];
      } else {
        items.value = data;
      }
    };

    const onLoadMore = async (q?: string) => {
      const data = await props.loadDictionaryFn(
        q ?? searchQuery.value,
        page.value
      );
      items.value.push(...data);
      if (data.length <= 0) {
        hasMore.value = false;
      }
    };

    const onChecked = (item: DictionaryValue) => {
      emit('checked', item);
    };

    watch(searchQuery, () => {
      onFilterList(searchQuery.value);
    });

    watch(isEndOfScroll, async () => {
      if (isEndOfScroll.value && hasMore.value) {
        page.value = page.value + 1;
        await onLoadMore();
      }
    });

    const onScroll = () => {
      if (wrapperEl.value !== null) {
        checkEndOfScroll(wrapperEl.value);
      }
    };

    onMounted(async () => {
      await onLoadList();
      listIsReady.value = true;
    });

    return () => (
      <div ref={wrapperEl} onScroll={onScroll} class={styles.selectListWrapper}>
        {!listIsReady.value ? (
          <Center>
            <Loader size={LoaderSize.XL} />
          </Center>
        ) : (
          <>
            <div class={'fixed w-full bg-bgApp'}>
              <div class={'py-2 px-4'}>
                <Input
                  variant={InputVariant.SECONDARY}
                  placeholder={t('component.dictionary.search')}
                  v-model={searchQuery.value}
                >
                  {{
                    leftIcon: () => (
                      <Icon class={'text-xl'} name={'magnifying-glass'} />
                    ),
                  }}
                </Input>
              </div>
            </div>

            <div class={styles.selectList}>
              {items.value.map((item) => {
                return (
                  <DictionaryItem
                    key={`dictionary-item-${item.key}`}
                    item={item}
                    isChecked={item.key === props?.selectedItem?.key}
                    onChecked={onChecked}
                  />
                );
              })}
            </div>
            {items.value.length <= 0 && slots?.emptyList?.()}
          </>
        )}
      </div>
    );
  },
});
