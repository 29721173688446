import light from './light';
import dark from './dark';
import { IThemes } from './utils';

export { applyTheme, extendTheme, extend } from './utils';

/**
 * The default theme to load
 */
export const DEFAULT_THEME = 'light';

export const themes: IThemes = {
  light,
  dark,
};
