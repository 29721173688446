import { api as client, Response } from '@/api';
import {
  IUploadStatRequest,
  IUploadStatResponse,
  IUploadRequest,
  IUploadResponse,
} from '@/api/disk/types';

export default {
  uploadStat: async (
    taskId: string,
    options?: RequestInit
  ): Promise<Response<IUploadStatResponse>> => {
    return await client.get<IUploadStatResponse, IUploadStatRequest>(
      '/api/disk/v1/upload/stat',
      { id: taskId },
      options
    );
  },

  upload: async (
    name: string,
    body: ArrayBuffer,
    options?: RequestInit
  ): Promise<Response<IUploadResponse>> => {
    return await client.post<IUploadResponse, IUploadRequest>(
      `/api/disk/v1/upload/${name}`,
      body,
      Object.assign({}, options, {
        headers: {
          'content-type': 'application/octet-stream',
        },
      })
    );
  },
};
