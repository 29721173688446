import { api as client, Response } from '@/api';
import {
  IGetADInfoRequest,
  IAd,
  ISetADInfoRequest,
  IGetListRequest,
  IGetListResponse,
} from '@/api/ad/types';

export default {
  getADInfo: async (payload: IGetADInfoRequest): Promise<Response<IAd>> => {
    return await client.get<IAd, IGetADInfoRequest>('/api/ad/v1/info', payload);
  },

  setADInfo: async (payload: ISetADInfoRequest): Promise<Response<IAd>> => {
    return await client.post<IAd, ISetADInfoRequest>(
      '/api/ad/v1/info',
      payload
    );
  },

  getList: async (
    payload: IGetListRequest
  ): Promise<Response<IGetListResponse>> => {
    return await client.get<IGetListResponse, IGetListRequest>(
      '/api/ad/list',
      payload
    );
  },
};
