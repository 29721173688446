import { defineComponent, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useTelegramWebApp } from '@/plugins';
import { useAppStore, useAuthStore } from '@/store';
import { useI18n } from 'vue-i18n';
import {
  Container,
  InputGroup,
  GroupItem,
  IconBgColor,
  Text,
  SelectVariant,
  SelectItem,
  Modal,
  Select,
  Dictionary,
  DictionaryValue,
} from '@/components/ui';
import { api } from '@/api';

export default defineComponent({
  setup: () => {
    const { t, locale } = useI18n();
    const router = useRouter();
    const appStore = useAppStore();
    const authStore = useAuthStore();
    const tgWebApp = useTelegramWebApp();

    const language = ref({} as SelectItem);
    const languageList = [
      { key: 'ru', name: t('dictionary.language.ru') },
      { key: 'en', name: t('dictionary.language.en') },
    ] as DictionaryValue[];

    const openChannel = () => {
      tgWebApp.openTelegramLink('https://t.me/+SR3Vg8EXUeQ4MTMy');
    };

    const openSupport = () => {
      tgWebApp.openTelegramLink('https://t.me/unlyDatingSupport');
    };

    const openAgreement = () => {
      tgWebApp.openLink('https://unly.ru/docs/agreement/');
    };

    const openConfidence = () => {
      tgWebApp.openLink('https://unly.ru/docs/confidence/');
    };

    const updateBackButton = () => {
      appStore.showBackButton(() => {
        router.back();
      });
    };

    const onLoadLanguageList = async (): Promise<DictionaryValue[]> => {
      return languageList;
    };

    const updateMainButton = () => {
      appStore.hideMainButton();
    };

    const updateButtons = () => {
      updateBackButton();
      updateMainButton();
    };

    onMounted(async () => {
      updateButtons();

      const langCode = authStore.getLanguageCode();
      const langItem = languageList.find((v) => {
        return v.key == langCode;
      });

      if (langItem) {
        language.value = langItem;
      }
    });

    watch(language, (newLang: SelectItem) => {
      const langCode = authStore.getLanguageCode();

      if (langCode !== newLang.key) {
        locale.value = newLang.key;
        api.setLanguageCode(newLang.key);
        authStore.setLanguageCode(newLang.key);
      }
    });

    return () => (
      <Container class={'mt-8'}>
        <InputGroup class={'mb-8'}>
          <GroupItem icon={'globus'} iconBgColor={IconBgColor.VIOLET}>
            <Select
              variant={SelectVariant.TRANSPARENCY}
              placeholder={t('views.settings.languagePlaceholder')}
              v-model={language.value}
            >
              {{
                dictionaryList: (
                  isOpen: boolean,
                  selectedItem: SelectItem,
                  onChecked: () => void,
                  onClose: () => void
                ) => (
                  <Modal
                    isOpen={isOpen}
                    onClose={onClose}
                    onClosed={updateButtons}
                  >
                    {{
                      content: () => (
                        <Dictionary
                          selectedItem={selectedItem}
                          onChecked={onChecked}
                          loadDictionaryFn={onLoadLanguageList}
                        />
                      ),
                    }}
                  </Modal>
                ),
              }}
            </Select>
          </GroupItem>
        </InputGroup>

        <InputGroup class={'mb-8'}>
          <GroupItem icon={'radio-solid'} iconBgColor={IconBgColor.ORANGE}>
            <Text onClick={openChannel}>{t('views.settings.news')}</Text>
          </GroupItem>
          <GroupItem
            icon={'chat-buddler-solid'}
            iconBgColor={IconBgColor.YELLOW}
          >
            <Text onClick={openSupport}>{t('views.settings.support')}</Text>
          </GroupItem>
        </InputGroup>

        <InputGroup>
          <GroupItem icon={'document-txt-solid'} iconBgColor={IconBgColor.BLUE}>
            <Text onClick={openAgreement}>{t('views.settings.agreement')}</Text>
          </GroupItem>
          <GroupItem icon={'newspaper-solid'} iconBgColor={IconBgColor.GREEN}>
            <Text onClick={openConfidence}>
              {t('views.settings.confidence')}
            </Text>
          </GroupItem>
        </InputGroup>
      </Container>
    );
  },
});
