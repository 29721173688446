import { api as client, Response } from '@/api';
import {
  IGetDesireListRequest,
  IGetDesireListResponse,
  IGetGenderListRequest,
  IGetGenderListResponse,
  IGetSexOrientationListRequest,
  IGetSexOrientationListResponse,
  IGetValuesRequest,
  IGetValuesResponse,
  ISearchCityListRequest,
  ISearchCityListResponse,
} from '@/api/dictionary/types';

export default {
  searchCityList: async (
    payload: ISearchCityListRequest
  ): Promise<Response<ISearchCityListResponse>> => {
    return await client.get<ISearchCityListResponse, ISearchCityListRequest>(
      '/api/dictionary/v1/search/city',
      payload
    );
  },

  getGenderList: async (
    payload: IGetGenderListRequest
  ): Promise<Response<IGetGenderListResponse>> => {
    return await client.get<IGetGenderListResponse, IGetGenderListRequest>(
      '/api/dictionary/v1/gender',
      payload
    );
  },

  getDesireList: async (
    payload: IGetDesireListRequest
  ): Promise<Response<IGetDesireListResponse>> => {
    return await client.get<IGetDesireListResponse, IGetDesireListRequest>(
      '/api/dictionary/v1/desire',
      payload
    );
  },

  getSexOrientationList: async (
    payload: IGetSexOrientationListRequest
  ): Promise<Response<IGetSexOrientationListResponse>> => {
    return await client.get<
      IGetSexOrientationListResponse,
      IGetSexOrientationListRequest
    >('/api/dictionary/v1/sex-orientation', payload);
  },

  getValues: async (
    payload: IGetValuesRequest
  ): Promise<Response<IGetValuesResponse>> => {
    return await client.get<IGetValuesResponse, IGetValuesRequest>(
      '/api/dictionary/v1/values',
      payload
    );
  },
};
