import { reactive, readonly } from 'vue';
import { defineStore } from 'pinia';
import { useAuthStore } from '@/store';
import { useTelegramWebApp } from '@/plugins';
import { applyTheme, DEFAULT_THEME, themes } from '@/themes';
import { ITheme } from '@/themes/utils';

interface mainButton {
  isShown: boolean;
  text: string;
  cb: (() => void) | null;
}

interface backButton {
  isShown: boolean;
  cb: (() => void) | null;
}

export const useAppStore = defineStore('app', () => {
  const authStore = useAuthStore();
  const tgWebApp = useTelegramWebApp();

  let mainButton = reactive({
    isShown: false,
    text: '',
    cb: null,
  } as mainButton);

  let backButton = reactive({
    isShown: false,
    cb: null,
  } as backButton);

  const authMiddleware = async (): Promise<boolean> => {
    const data = tgWebApp.getInitData();
    return await authStore.tgWebAppSignIn({ data });
  };

  const showMainButton = (text: string, cb: () => void) => {
    if (mainButton.cb !== null) {
      tgWebApp.offMainButtonClick(mainButton.cb);
    }

    mainButton = Object.assign(mainButton, {
      cb: cb,
      text: text,
      isShown: true,
    });
    tgWebApp.onMainButtonClick(cb);
    tgWebApp.showMainButton(mainButton.text);
  };

  const hideMainButton = () => {
    if (mainButton.cb !== null) {
      tgWebApp.offMainButtonClick(mainButton.cb);
    }

    mainButton = Object.assign(mainButton, {
      cb: null,
      isShown: false,
    });
    tgWebApp.hideMainButton();
  };

  const showBackButton = (cb: () => void) => {
    if (backButton.cb !== null) {
      tgWebApp.offBackButtonClick(backButton.cb);
    }

    backButton = Object.assign(backButton, {
      cb: cb,
      isShown: true,
    });
    tgWebApp.onBackButtonClick(cb);
    tgWebApp.showBackButton();
  };

  const hideBackButton = () => {
    if (backButton.cb !== null) {
      tgWebApp.offBackButtonClick(backButton.cb);
    }

    backButton = Object.assign(backButton, {
      cb: null,
      isShown: false,
    });
    tgWebApp.hideBackButton();
  };

  const showAlert = (msg: string) => {
    tgWebApp.showAlert(msg);
  };

  const getTheme = (): ITheme => {
    let themeName = tgWebApp.getColorScheme();
    if (typeof themes[tgWebApp.getColorScheme()] === 'undefined') {
      themeName = DEFAULT_THEME;
    }

    return themes[themeName] as ITheme;
  };

  const applyColorSchema = () => {
    const curTheme = getTheme();
    tgWebApp.setHeaderColor(curTheme.bgApp);
    tgWebApp.setBackgroundColor(curTheme.bgApp);
    document.documentElement.classList.add(tgWebApp.getColorScheme());

    applyTheme(curTheme);
  };

  const resetHeaderColor = () => {
    const curTheme = getTheme();
    tgWebApp.setHeaderColor(curTheme.bgApp);
  };

  const setNightMode = () => {
    tgWebApp.setHeaderColor('#000');
  };

  return {
    authMiddleware,
    showMainButton,
    hideMainButton,
    showBackButton,
    hideBackButton,
    showAlert,
    applyColorSchema,
    resetHeaderColor,
    setNightMode,
    mainButton: readonly(mainButton),
  };
});
