import { defineComponent, PropType } from 'vue';
import styles from './index.css?module';

export enum VARIANT {
  HEART,
  SIMPLE,
}

export enum SIZE {
  SM,
  MD,
  LG,
  XL,
}

export default defineComponent({
  props: {
    size: {
      type: Number as PropType<SIZE>,
      default: SIZE.SM,
    },
    variant: {
      type: Number as PropType<VARIANT>,
      default: VARIANT.HEART,
    },
  },

  setup(props, { slots }) {
    const sizeStyle = () => {
      switch (props.size) {
        default:
        case SIZE.SM:
          return styles.loaderSM;
        case SIZE.MD:
          return styles.loaderMD;
        case SIZE.LG:
          return styles.loaderLG;
        case SIZE.XL:
          return styles.loaderXL;
      }
    };

    const variantStyle = () => {
      switch (props.variant) {
        default:
        case VARIANT.HEART:
          return styles.loaderHeart;
        case VARIANT.SIMPLE:
          return styles.loaderSimple;
      }
    };

    return () => <div class={[variantStyle(), sizeStyle()]}></div>;
  },
});
