export default {
  bgApp: '#EFEFF4',

  bgPrimary: '#037EE5',
  bgSecondary: '#8A8A8E',
  bgSecondaryLight: '#d0d0d4',

  textWhite: '#ffffff',
  textPrimary: '#000000',
  textSecondary: '#8A8A8E',
  textSecondaryDark: '#636366',

  textHighlight: '#037EE5',
  textHighlightDanger: '#FE3B30',

  bgField: '#ffffff',
  bgFieldSecondary: '#e3e3e8',
};
