import { defineComponent, computed, ref } from 'vue';
import styles from './index.css?module';
import { Text, TextSize, TextColor } from '@/components/ui';

export default defineComponent({
  props: {
    modelValue: {
      type: String,
    },
    id: {
      type: String,
    },
    maxlength: {
      type: Number,
    },
    placeholder: {
      type: String,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const el = ref({} as HTMLElement);
    const syncModel = computed({
      get() {
        return props.modelValue;
      },
      set(newVal) {
        emit('update:modelValue', newVal);
      },
    });

    const curLength = computed((): number => {
      return (syncModel.value as string)?.length ?? 0;
    });

    const checkClosestClick = (e: TouchEvent) => {
      if (!el.value) {
        return;
      }
      if (e.target != el.value) {
        el.value?.blur();
      }
    };

    const onFocus = () => {
      document.addEventListener('touchstart', checkClosestClick);
    };

    const onBlur = () => {
      document.removeEventListener('touchstart', checkClosestClick);
    };

    return () => (
      <div>
        <textarea
          class={[
            styles.inputTextArea,
            props.hasError && styles.inputTextAreaWithError,
          ]}
          v-model={syncModel.value}
          ref={el}
          id={props.id}
          maxlength={props.maxlength}
          placeholder={props.placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        {(props.maxlength ?? 0) > 0 && (
          <div class={'flex flex-row justify-end pr-0.5'}>
            <Text color={TextColor.SECONDARY} size={TextSize.CAPTION}>
              {curLength.value} / {props.maxlength}
            </Text>
          </div>
        )}
      </div>
    );
  },
});
