import { defineComponent, PropType, computed } from 'vue';
import styles from './index.css?module';
import {
  PhotoCard,
  UploadFile,
  Text,
  TextSize,
  TextColor,
} from '@/components/ui';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  props: {
    modelValue: {
      type: Array as PropType<UploadFile[]>,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const getModel = (index: number) => {
      return computed({
        get() {
          return props.modelValue?.[index];
        },
        set(newVal) {
          const newState = props.modelValue?.map((v, i) => {
            return i === index ? newVal : v;
          });
          emit('update:modelValue', newState);
        },
      });
    };

    return () => (
      <div class={styles.photoPickerWrapper}>
        <div class={styles.photoPickerList}>
          {props.modelValue?.map((model: UploadFile, index: number) => {
            return (
              <PhotoCard
                key={`photo-${index}`}
                v-model={getModel(index).value}
              />
            );
          })}
        </div>
        <Text color={TextColor.SECONDARY} size={TextSize.CAPTION}>
          {t('component.photoPicker.maxFileSizeDesc')}
        </Text>
      </div>
    );
  },
});
