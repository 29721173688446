import { defineComponent, ref, PropType } from 'vue';
import styles from './index.css?module';
import { Icon, IconSize } from '@/components/ui';

export enum IconBgColor {
  ORANGE,
  YELLOW,
  BLUE,
  GREEN,
  VIOLET,
}

export enum IconColor {
  BLACK,
  WHITE,
  RED,
}

export default defineComponent({
  props: {
    disableHighlight: {
      type: Boolean,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    iconBgColor: {
      type: Number as PropType<IconBgColor>,
      required: false,
    },
    iconColor: {
      type: Number as PropType<IconColor>,
      default: IconColor.WHITE,
    },
    onClick: {
      type: Function,
    },
  },

  setup: (props, { slots, emit }) => {
    const isActive = ref(false);
    const onTouchStart = () => {
      if (props.disableHighlight) {
        return;
      }
      isActive.value = true;
    };
    const onTouchEnd = () => {
      if (props.disableHighlight) {
        return;
      }
      isActive.value = false;
    };

    const iconBgColor = () => {
      switch (props.iconBgColor) {
        case IconBgColor.ORANGE:
          return styles.iconBgColorOrange;
        case IconBgColor.YELLOW:
          return styles.iconBgColorYellow;
        case IconBgColor.BLUE:
          return styles.iconBgColorBlue;
        case IconBgColor.GREEN:
          return styles.iconBgColorGreen;
        case IconBgColor.VIOLET:
          return styles.iconBgColorViolet;
      }
    };

    const iconColor = () => {
      switch (props.iconColor) {
        case IconColor.BLACK:
          return styles.iconColorBlack;
        case IconColor.WHITE:
          return styles.iconColorWhite;
        case IconColor.RED:
          return styles.iconColorRed;
      }
    };

    return () => (
      <div
        class={[
          styles.inputGroupItem,
          isActive.value && styles.inputGroupItemActive,
        ]}
        onTouchstart={onTouchStart}
        onTouchend={onTouchEnd}
        onClick={(e) => emit('click', e)}
      >
        <div
          class={[
            'flex flex-row items-center justify-center',
            !props.icon && 'pr-4',
          ]}
        >
          {props.icon && (
            <div class={[styles.itemIconWrapper, iconBgColor()]}>
              <Icon
                class={[styles.itemIcon, iconColor()]}
                name={props.icon}
                size={IconSize.X20}
              />
            </div>
          )}
        </div>
        <div class={styles.inputGroupItemContent}>{slots?.default?.()}</div>
      </div>
    );
  },
});
