import { ref, readonly } from 'vue';
import { defineStore } from 'pinia';

export enum TOAST_TYPE {
  SUCCESS,
  ERROR,
  INFO,
}

export interface Toast {
  id: number;
  message: string;
  delay: number;
  type: TOAST_TYPE;
  timerId?: number;
}

export const useToast = defineStore('toast', () => {
  const count = ref(0);
  const list = ref<Toast[]>([]);

  const add = (type: TOAST_TYPE, msg: string, delay?: number) => {
    const toast = {
      id: count.value++,
      message: msg,
      delay: delay || 3000,
      type,
    } as Toast;

    // Remove all previous
    list.value.forEach((item) => {
      clear(item);
    });

    // Add new
    list.value.push(toast);

    toast.timerId = setTimeout(() => {
      clear(toast);
    }, toast.delay);
  };

  const clear = (notice: Toast) => {
    clearTimeout(notice.timerId);

    const index = list.value.findIndex((toast) => toast === notice);
    list.value.splice(index, 1);
  };

  return {
    list: readonly(list),
    add,
    clear,
  };
});
