import { readonly, ref } from 'vue';
import { defineStore } from 'pinia';
import { api, IAd, IAdInfo, IGetListRequest } from '@/api/ad';
import { RequestErrorDetails } from '@/api';

export const useAdStore = defineStore('ad', () => {
  const ad = ref<IAd>();
  const adList = ref<IAdInfo[]>([]);

  const loadAd = async () => {
    const resp = await api.getADInfo({});
    if (resp.ok) {
      ad.value = resp.payload;
    }

    return resp;
  };

  const loadAdList = async (payload: IGetListRequest) => {
    const resp = await api.getList(payload);
    if (resp.ok) {
      adList.value = resp.payload.data;
    }

    return resp;
  };

  const setAdLike = (id: string) => {
    const adItem = adList.value.find((x) => x.id === id);
    if (adItem) {
      adItem.hasLike = true;
    }
  };

  const loadMoreAdList = async (payload: IGetListRequest) => {
    const resp = await api.getList(payload);
    if (resp.ok) {
      adList.value = adList.value.concat(resp.payload.data);
    }

    return resp;
  };

  const updateAd = async (
    description: string,
    isActive: boolean
  ): Promise<RequestErrorDetails[]> => {
    const resp = await api.setADInfo({ description, isActive });
    if (!resp.ok) {
      return resp.error;
    }

    ad.value = resp.payload;
    return [];
  };

  return {
    ad: readonly(ad),
    adList: readonly(adList),
    loadAd,
    updateAd,
    loadAdList,
    loadMoreAdList,
    setAdLike,
  };
});
