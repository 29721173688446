import { defineComponent, PropType } from 'vue';
import { TextColor } from '@/components/ui';

export enum SIZE {
  H1,
  H2,
  H3,
  H4,
  BASE,
  CAPTION,
}

export enum WEIGHT {
  BASE,
  LIGHT,
  BOLD,
}

export default defineComponent({
  props: {
    size: {
      type: Number as PropType<SIZE>,
      default: SIZE.BASE,
    },
    weight: {
      type: Number as PropType<WEIGHT>,
      default: WEIGHT.BASE,
    },
    color: {
      type: String as PropType<TextColor>,
      default: () => TextColor.PRIMARY,
    },
    onClick: {
      type: Function,
    },
  },

  setup(props, { slots, emit }) {
    const sizeStyle = () => {
      switch (props.size) {
        case SIZE.H1:
          return 'text-3xl';
        case SIZE.H2:
          return 'text-2xl';
        case SIZE.H3:
          return 'text-xl';
        case SIZE.H4:
          return 'text-lg';
        default:
        case SIZE.BASE:
          return 'text-base';
        case SIZE.CAPTION:
          return 'text-sm';
      }
    };

    const weightStyle = () => {
      switch (props.weight) {
        case WEIGHT.BASE:
          return 'font-normal';
        case WEIGHT.LIGHT:
          return 'font-light';
        case WEIGHT.BOLD:
          return 'font-medium';
      }
    };

    return () => (
      <span
        class={[sizeStyle(), weightStyle(), props.color]}
        onClick={() => emit('click')}
      >
        {slots?.default?.()}
      </span>
    );
  },
});
