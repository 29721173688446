import { defineComponent, ref, PropType, computed } from 'vue';
import styles from './index.css?module';
import { Text, TextColor, Icon } from '@/components/ui';

export enum VARIANT {
  BASE,
  TRANSPARENCY,
}

export interface SelectItem {
  key: string;
  name: string;
}

export default defineComponent({
  props: {
    modelValue: {
      type: Object as PropType<SelectItem>,
    },
    variant: {
      type: Number as PropType<VARIANT>,
      default: VARIANT.BASE,
    },
    title: {
      type: String,
    },
    placeholder: {
      type: String,
    },
  },

  setup(props, { slots, emit }) {
    const isOpen = ref(false);

    const variantWrapperStyle = (): string => {
      switch (props.variant) {
        case VARIANT.BASE:
          return styles.selectWrapperBase;
        case VARIANT.TRANSPARENCY:
          return '';
      }
    };

    const textColorStyle = (): TextColor => {
      if (!props.modelValue?.key) {
        return TextColor.SECONDARY;
      }
      return TextColor.PRIMARY;
    };

    const onOpenList = async () => {
      isOpen.value = true;
    };

    const onChecked = (item: SelectItem) => {
      emit('update:modelValue', item);
      onClose();
    };

    const onClose = () => {
      isOpen.value = false;
    };

    const getTitle = computed((): string | undefined => {
      if (!props.title) {
        return props.modelValue?.name || props.placeholder;
      }
      return props.title;
    });

    const getValue = computed((): string | undefined => {
      if (props.title) {
        return props.modelValue?.name;
      }
      return '';
    });

    return () => (
      <>
        <div
          class={[styles.selectWrapper, variantWrapperStyle()]}
          onClick={onOpenList}
        >
          <div class={styles.selectTextWrapper}>
            <div>
              <Text color={textColorStyle()}>{getTitle.value}</Text>
            </div>
            <div>
              <Text>{getValue.value}</Text>
            </div>
          </div>
          <div class={styles.inputRightIcon}>
            <Icon name={'chevron-right'} />
          </div>
        </div>

        {slots?.dictionaryList?.(
          isOpen.value,
          props.modelValue,
          onChecked,
          onClose
        )}
      </>
    );
  },
});
