export function usePluralize() {
  const pluralize = (cnt: number, words: string[]): string => {
    cnt = Math.abs(cnt) % 100;
    const num = cnt % 10;
    if (cnt > 10 && cnt < 20) return words[2];
    if (num > 1 && num < 5) return words[1];
    if (num == 1) return words[0];
    return words[2];
  };

  return {
    pluralize,
  };
}

export const i18nRuPluralize = (cnt: number, choicesLength: number): number => {
  cnt = Math.abs(cnt) % 100;
  const num = cnt % 10;
  if (cnt > 10 && cnt < 20) return 2;
  if (num > 1 && num < 5) return 1;
  if (num == 1) return 0;
  return 2;
};
