import { defineStore } from 'pinia';
import { Response } from '@/api';
import { api, ILikeResponse } from '@/api/cupid';

export const useCupidStore = defineStore('cupid', () => {
  const like = async (userId: string): Promise<Response<ILikeResponse>> => {
    return await api.like({ userId });
  };

  return {
    like,
  };
});
